import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', function (date) {
  return date ? moment(date).format('DD/MM/YYYY') : ''
})

Vue.filter('formatDatetime', function (date) {
  return date ? moment(date).format('DD/MM/YYYY HH:mm') : ''
})

Vue.filter('currence', function (value) {
  if (value == null) {
    return value
  } else {
    if (typeof value === 'number') {
      return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
    } else {
      return parseFloat(value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
    }
  }
})

Vue.filter('truncateStr', function (string) {
  if (string.length >= 20) {
    return string.substring(0, 20) + '...'
  } else {
    return string
  }
})

Vue.mixin({
  methods: {
    calcProjectPercentage (valueGoal, valueReached) {
      return (valueReached * 100 / valueGoal).toFixed(2)
    },
    calcProjectRestDays (startAt, finishAt) {
      return moment(finishAt).diff(moment(startAt), 'days')
    },
    currenceVal (value) {
      if (value == null) {
        return value
      } else {
        if (typeof value === 'number') {
          return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
        } else {
          return parseFloat(value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
        }
      }
    }
  }
})
