import { apiDomainUrl, appDomainUrl, nciDomainUrl } from './domains'

export const apiDomain = apiDomainUrl
export const apiUrl = `${apiDomainUrl}api/`
export const appDomain = appDomainUrl
export const nciDomain = nciDomainUrl

export function getBearer () {
  let authUser = JSON.parse(window.localStorage.getItem('authUser'))
  if (authUser) {
    return {'Authorization': 'Bearer ' + authUser.access_token}
  } else {
    getHeaderPublic()
  }
}

export function getFormDataBearer () {
  let authUser = JSON.parse(window.localStorage.getItem('authUser'))
  if (authUser) {
    return {
      'Authorization': 'Bearer ' + authUser.access_token,
      'Accept': 'application/json, image/*, file/*, video/*',
      'Content-Type': 'multipart/form-data; boundary=something'
    }
  } else {
    getHeaderPublicFormData()
  }
}

export const getHeaderPublic = function () {
  return {'Accept': 'application/json'}
}

export const getHeaderPublicFormData = function () {
  return {'Accept': 'application/json', 'Content-Type': 'multipart/form-data'}
}

export const _roles = {
  adm_role: 1,
  pf_role: 2,
  pj_role: 3
}

export const ENDPOINTS = {

  oauth: `${apiUrl}login`,
  registerUser: `${apiUrl}register-user`,
  users: `${apiUrl}users`,

  forgotPassword: `${apiUrl}forgot-password`,
  checkRecoveryPassCode: `${apiUrl}check-recovery-password-code`,
  updatePassword: `${apiUrl}update-password/`,

  notifications: `${apiUrl}notifications/`,
  categories: `${apiUrl}categories/`,
  ods: `${apiUrl}ods/`,

  projects: `${apiUrl}projects/`,
  closeProject: `${apiUrl}close-project/`,

  ncis: `${apiUrl}ncis/`,
  userNCIs: `${apiUrl}get-user-ncis/`,
  bidNCI: `${apiUrl}bid-nci/`,

  donate: `${apiUrl}donate/`,

  walletExtract: `${apiUrl}wallet-extract`

}
