import axios from 'axios'
import {ENDPOINTS, getBearer} from '@/config'

export default {
  state: {
    walletExtract: []
  },
  mutations: {
    setWalletData (state, walletParams) {
      state[walletParams.state] = walletParams.value
    }
  },
  actions: {
    getWalletExtract ({commit}) {
      return axios.get(ENDPOINTS.walletExtract, {headers: getBearer()}).then(resp => {
        if (resp.status === 200) {
          commit('setWalletData', {state: 'walletExtract', value: resp.data})
        }
        return resp
      })
    }
  }
}
