import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {MediaQueries, CommonBands} from 'vue-media-queries'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import '@/plugin/filters'
// import axios from 'axios'

const mediaQueries = new MediaQueries({
  bands: CommonBands.Bulma
})
Vue.use(mediaQueries)

// axios.interceptors.response.use(
//   response => {
//     if (response.status === 200 || response.status === 201) {
//       return Promise.resolve(response);
//     }
//   },
//   error => {
//     console.log(error)
//     if (error.response && error.response.status) {
//       switch (error.response.status) {
//         case 401:
//           if (['invalid_token', 'Unauthenticated.'].includes(error.response.data.message)) {
//             store.dispatch('setAlertDanger', 'Usuário não autenticado ou sessão expirada.')
//             window.localStorage.clear()
//             window.location = '/'
//             break
//           }
//       }
//       return Promise.reject(error.response);
//     } else {
//       if (error.code === "ERR_NETWORK") {
//         store.dispatch('setAlertDanger', 'Sistema indisponível no momento. Tente novamente dentre alguns minutos ou entre em contato com o suporte técnico.')
//         window.localStorage.clear()
//         window.location = '/'
//       }
//     }
//   }
// );

import VueResource from 'vue-resource'
// import './registerServiceWorker'

Vue.use(VueResource)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  // store.dispatch('hideAlert')
  let authUser = JSON.parse(window.localStorage.getItem('authUser'))
  if (to.meta.requires_auth) {
    if (!authUser || (authUser && !authUser.user)) {
      if (from.name === 'login') {
        return false
      }
      window.localStorage.clear()
      next('/login')
      return false
    }
  }
  if ((to.name === 'login' || to.path === '/') && authUser) {
    next('/dashboard')
  }
  next()
  let footer = document.querySelector('footer')
  if (footer) {
    if (['Novo Projeto', 'Detalhes do Projeto'].includes(to.name)) {
      footer.classList.remove('fixed')
    } else {
      footer.classList.add('fixed')
    }
  }
})

new Vue({
  router,
  store,
  mediaQueries: mediaQueries,
  render: h => h(App)
}).$mount('#app')
