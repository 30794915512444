import axios from 'axios'
import {ENDPOINTS, getBearer} from '@/config'

export default {
  state: {
    projects: []
  },
  mutations: {
    setProjects (state, projects) {
      state.projects = projects
    }
  },
  actions: {
    storeProject ({commit}, params) {
      return axios.post(ENDPOINTS.projects, params, {headers: getBearer()}).then(resp => {
        if (resp.status === 200) {
          commit('setProjects', resp.data)
        }
        return resp
      })
    },
    getProjects ({commit}) {
      return axios.get(ENDPOINTS.projects, {headers: getBearer()}).then(resp => {
        if (resp.status === 200) {
          commit('setProjects', resp.data)
        }
        return resp
      })
    },
    getProject (context, projectSlug) {
      return axios.get(ENDPOINTS.projects + projectSlug, {headers: getBearer()}).then(resp => {
        return resp
      })
    },
    donateProject ({commit}, params) {
      return axios.post(ENDPOINTS.donate, params, {headers: getBearer()}).then(resp => {
        if (resp.status === 200) {
          commit('setProjects', resp.data)
        }
        return resp
      })
    },
    closeProject (context, params) {
      return axios.post(ENDPOINTS.closeProject, params, {headers: getBearer()}).then(resp => {
        return resp
      })
    }
  }
}
