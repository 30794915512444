import axios from 'axios'
import {ENDPOINTS, getBearer} from '@/config'

function authUser () {
  let authUser = JSON.parse(window.localStorage.getItem('authUser'))
  return authUser ? authUser : {user: null}
}

export default {
  state: {
    userData: null
  },
  mutations: {
    setUserData (state, image) {
      state.userData = image
    }
  },
  actions: {

    setUserData ({commit}, data) {
      commit('setUserData', data)
    },

    login ({commit}, params) {
      return axios.post(ENDPOINTS.oauth, params, {}).then(resp => {
        commit('setUserData', resp.data.user)
        window.localStorage.setItem('authUser', JSON.stringify(resp.data))
        return resp.data
      }).catch(error => {
        return error
      })
    },

    registerUser (context, params) {
      return axios.post(ENDPOINTS.registerUser, params, {})
        .then(resp => {
          return resp
        }).catch(resp => {
          return resp
        })
    },

    forgotPassword (context, params) {
      return axios.post(ENDPOINTS.forgotPassword, params, {}).then(resp => {
        return resp
      }).catch(error => {
        return error
      })
    },

    checkRecoveryPasswordCode (context, params) {
      return axios.post(ENDPOINTS.checkRecoveryPassCode, params, {}).then(resp => {
        return resp
      }).catch(error => {
        return error
      })
    },

    updatePassword (context, params) {
      return axios.put(`${ENDPOINTS.updatePassword}${params.user_id}`, params, {headers: getBearer()})
        .then(resp => {
          return resp.data
        }).catch(error => {
          return error
        })
    },

    getAllUsers (context, params) {
      return axios.get(ENDPOINTS.usersAll, {params}, {headers: getBearer()}).then(resp => {
        return resp.data
      }).catch(error => {
        return error
      })
    },

    getUser ({commit}, id) {
      return axios.get(`${ENDPOINTS.users}/${id}`, {headers: getBearer()}).then(resp => {
        let auth = authUser()
        auth.user = resp.data
        commit('setUserData', resp.data)
        window.localStorage.setItem('authUser', JSON.stringify(auth))
        return resp.data
      })
    },

    updateUser ({commit}, params) {
      return axios.put(`${ENDPOINTS.users}/${params.id}`, params, {headers: getBearer()})
        .then(resp => {
          commit('setUserData', resp.data)
          let authUser = JSON.parse(window.localStorage.getItem('authUser'))
          authUser.user = resp.data
          window.localStorage.setItem('authUser', JSON.stringify(authUser))
        }).catch(error => {
          return error
        })
    },

    deleteUser (context, id) {
      return axios.delete(`${ENDPOINTS.users}/${id}`, {headers: getBearer()})
    }

  }
}
