import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'app',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '',
    name: 'base',
    component: () => import('../Base.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue'),
        meta: {
          subtitle: false,
          requires_auth: true
        }
      },
      {
        path: '/invest',
        name: 'Investir',
        component: () => import('../views/pj/CaptureView.vue'),
        meta: {
          subtitle: false,
          requires_auth: true
        }
      },
      {
        path: '/capture',
        name: 'Captar',
        component: () => import('../views/pj/CaptureView.vue'),
        meta: {
          subtitle: '~ Torne seus projetos realidade!',
          requires_auth: true
        }
      },
      {
        path: '/voluntarie',
        name: 'Voluntário',
        component: () => import('../views/pf/Voluntary.vue'),
        meta: {
          subtitle: false,
          requires_auth: true
        }
      },
      {
        path: '/projects',
        name: 'Projetos',
        component: () => import('../views/pf/Projects.vue'),
        meta: {
          subtitle: false,
          requires_auth: true
        }
      },
      {
        path: '/ncis',
        name: 'NCIs',
        component: () => import('../views/pj/NCIs.vue'),
        meta: {
          subtitle: '~ Nobis Certificated Inscriptions',
          requires_auth: true
        }
      },
      {
        path: '/project/:project_slug',
        name: 'Detalhes do Projeto',
        component: () => import('../views/pf/ProjectView.vue'),
        meta: {
          subtitle: false,
          requires_auth: true
        }
      },
      {
        path: '/new-project',
        name: 'Novo Projeto',
        component: () => import('../views/pj/ProjectRegister.vue'),
        meta: {
          subtitle: false,
          requires_auth: true
        }
      },
      {
        path: '/wallet',
        name: 'Carteira',
        component: () => import('../views/pf/Wallet.vue'),
        meta: {
          subtitle: false,
          requires_auth: true
        }
      },
      {
        path: '/configs',
        name: 'Configurações',
        component: () => import('../views/Configs.vue'),
        meta: {
          subtitle: false,
          requires_auth: true
        }
      }
    ]
  },
  {
    path: '/sthation-store',
    name: 'NCIPage',
    component: () => import('../views/nci/Home.vue'),
  },
  {
    path: '/sthation-store/:slug',
    name: 'NCIProject',
    component: () => import('../views/nci/Home.vue'),
  },
  {
    path: '/sthation-hall',
    name: 'SthationHall',
    component: () => import('../views/hall/Home.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/errors/404Page.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
