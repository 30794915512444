import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import axios from 'axios'
import {apiUrl} from '@/config'

import alertStore from '@/store/alertStore'
import miscStore from '@/store/miscStore'
import nciStore from '@/store/nciStore'
import notificationStore from '@/store/notificationStore'
import projectStore from '@/store/projectStore'
import userStore from '@/store/userStore'
import walletStore from '@/store/walletStore'

axios.defaults.baseURL = apiUrl

export default new Vuex.Store({
  modules: {
    alertStore,
    miscStore,
    nciStore,
    notificationStore,
    projectStore,
    userStore,
    walletStore
  }
})
