import axios from 'axios'
import {ENDPOINTS, getBearer} from '@/config'

export default {
  state: {
    categoryApplied: '',
    filterApplied: '',
    ncis: [],
    originalNcis: []
  },
  mutations: {
    setNcis (state, ncis) {
      state.originalNcis = ncis
      state.ncis = ncis
    },
    filterNCIsByCategory (state, categoryId) {
      state.ncis = state.originalNcis.filter((nci) => nci.category_id === categoryId)
      state.categoryApplied = categoryId
    },
    filterNCIsBySearch (state, search) {
      if (search === '') {
        state.filterApplied = ''
        state.ncis = state.originalNcis
      } else {
        state.filterApplied = search
        let ncis = [...state.originalNcis]
        if (state.categoryApplied !== '') {
          ncis = state.originalNcis.filter((nci) => nci.category_id === state.categoryApplied)
        }
        state.ncis = ncis.filter((nci) => nci.title.toLowerCase().includes(search.toLowerCase()))
      }
    },
    resetNCIsList (state) {
      state.ncis = state.originalNcis
      state.categoryApplied = ''
      state.filterApplied = ''
    }
  },
  actions: {
    getNCIs ({commit}) {
      return axios.get(ENDPOINTS.ncis, {headers: getBearer()}).then(resp => {
        if (resp.status === 200) {
          commit('setNcis', resp.data)
        }
        return resp
      })
    },
    getUserNCIs ({commit}, param) {
      return axios.get(ENDPOINTS.userNCIs, {params: param, headers: getBearer()}).then(resp => {
        if (resp.status === 200) {
          commit('setNcis', resp.data)
        }
        return resp
      })
    },
    filterNCIs ({commit}, categoryId) {
      commit('filterNCIsByCategory', categoryId)
    },
    searchNCIs ({commit}, categoryId) {
      commit('filterNCIsBySearch', categoryId)
    },
    resetNCIsList ({commit}) {
      commit('resetNCIsList')
    },
    bidNCI ({commit}, params) {
      return axios.post(ENDPOINTS.bidNCI, params, {headers: getBearer()}).then(resp => {
        if (resp.status === 200) {
          commit('setNcis', resp.data)
        }
        return resp
      })
    }
  }
}
