import axios from 'axios'
import {ENDPOINTS, getBearer} from '@/config'

export default {
  state: {
    categories: [],
    ods: []
  },
  mutations: {
    setCategories (state, catories) {
      state.categories = catories
    },
    setOds (state, ods) {
      state.ods = ods
    }
  },
  actions: {
    getCategories ({commit}) {
      return axios.get(ENDPOINTS.categories, {headers: getBearer()}).then(resp => {
        if (resp.status === 200) {
          commit('setCategories', resp.data)
        }
        return resp
      })
    },
    getOds ({commit}) {
      return axios.get(ENDPOINTS.ods, {headers: getBearer()}).then(resp => {
        if (resp.status === 200) {
          commit('setOds', resp.data)
        }
        return resp
      })
    }
  }
}
