const host = window.location.host
const hostname = window.location.hostname
const isProduction = process.env.NODE_ENV === 'production'

let appDomainUrl = `http://${host}/`
let apiDomainUrl = `http://${hostname}:8000/`
let nciDomainUrl = `http://${hostname}:8080/sthation-store/`

if (isProduction) {
  appDomainUrl = `https://${host}/`
  apiDomainUrl = `https://api.sth.exocode.digital/`
  nciDomainUrl = `${appDomainUrl}sthation-store/`
}

export {appDomainUrl, apiDomainUrl, nciDomainUrl}
